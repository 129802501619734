.upload_container_square,
.upload_container {
    position: relative;
}
.upload_container_square input,
.upload_container input {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.upload_container_square label{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-decoration: underline;
    cursor: pointer;
}
.upload_container_square img{
    display: block;
}